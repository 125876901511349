import { Controller } from "@hotwired/stimulus"

import userAgentRegex from "../javascripts/lib/supported_browsers"

export default class extends Controller {
  #UNSUPPORTED_BROWSER = "unsupportedBrowser"
  #POPOVER_UNSUPPORTED_BROWSER = "popoverUnsupportedBrowser"

  connect() {
    const popoverSupported = "popover" in HTMLElement.prototype
    const isLoginPage = document.body.matches(
      `[data-ruby-controller-name="sessions"][data-ruby-action-name="new"]`
    )
    if (!isLoginPage && !popoverSupported) {
      this.#logOncePerSession(
        this.#POPOVER_UNSUPPORTED_BROWSER,
        "Popover not supported"
      )
    }

    const isSupported = userAgentRegex.test(navigator.userAgent)
    const isSearchResultsPage = document.body.matches(
      `[data-ruby-controller-name="searches"][data-ruby-action-name="edit"]`
    )
    if (isSupported || isSearchResultsPage) return

    this.element.style.display = "block"
    this.#logOncePerSession(
      this.#UNSUPPORTED_BROWSER,
      "Unsupported browser used"
    )
  }

  #logOncePerSession(cacheKey, errorMessage) {
    // This adds a flag in session storage so it only gets logged with BugSnag once per browser per session
    if (!sessionStorage.getItem(cacheKey)) {
      sessionStorage.setItem(cacheKey, "true")
      if (window.bugsnagClient) {
        window.bugsnagClient.notify(
          new Error(errorMessage, { cause: navigator.userAgent })
        )
      }
    }
  }
}
